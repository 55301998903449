import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: 'root',
        loadChildren: () => import('./root/root.module').then((m) => m.RootModule),
    },
    {
        path: 'error-pages',
        loadChildren: () => import('./error-pages/error-pages.module').then((m) => m.ErrorPagesModule),
    },
    { path: '', redirectTo: 'root' },
    { path: '**', redirectTo: 'root' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
