import { NavigationExtras } from '@angular/router';
import { createAction, props } from '@ngrx/store';

/**
 * Router action 'go' payload
 */
export interface RouterActionPayload {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    path: any[];
    query?: object; // Query params
    extras?: NavigationExtras;
}

/**
 * Action performing navigation to a route specified by RouterActionPayload object
 */
export const go = createAction('[Router] Go', props<RouterActionPayload>());

/**
 * Action performing a back navigation
 */
export const back = createAction('[Router] Back');

/**
 * Action performing a forward navigation
 */
export const forward = createAction('[Router] Forward');

/**
 * Action doing nothing, dispatch it in effect if there is nothing to do
 * but in effect, we have to dispatch and action at the end
 */
export const navigationLaunched = createAction('[Router] Navigation Launch');
