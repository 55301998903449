import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { HttpErrorInterceptor } from './error-handling/http-error.interceptor';
import { RouterEffects } from './store/effects/router.effects';
import { CustomRouterStateSerializer } from './store/reducers/router.reducers';

/**
 * Module which collects numerous, auxiliary, single-use classes (components, ...)
 * and singleton service whose instance will be shared throughout the application
 */
@NgModule({
    declarations: [],
    imports: [CommonModule, EffectsModule.forRoot([RouterEffects]), StoreRouterConnectingModule.forRoot(), ToastModule],
    providers: [
        { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
        MessageService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
    ],
    exports: [ToastModule],
})
export class CoreModule {
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: CoreModule
    ) {
        this.throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }

    private throwIfAlreadyLoaded(parentModule: CoreModule, moduleName: string) {
        if (parentModule) {
            throw new Error(`${moduleName} has already been loaded. Import Core modules in the AppModule only.`);
        }
    }
}
