import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { back, forward, go, navigationLaunched } from '../actions/router.actions';

@Injectable()
export class RouterEffects {
    constructor(private action$: Actions, private router: Router, private location: Location) {}

    /**
     * Effect listening to dispatched 'go' action and performing
     * navigation to route specified in the action's payload
     */
    go$ = createEffect(() =>
        this.action$.pipe(
            ofType(go),
            tap(({ path, query: queryParams, extras }) => this.router.navigate(path, { queryParams, ...extras })),
            map(() => navigationLaunched())
        )
    );

    /**
     * Effect listening to dispatched 'back' action and performing a navigation back
     */
    back$ = createEffect(() =>
        this.action$.pipe(
            ofType(back),
            tap(() => this.location.back()),
            map(() => navigationLaunched())
        )
    );

    /**
     * Effect listening to dispatched 'forward' action and performing a navigation forward
     */
    forward$ = createEffect(() =>
        this.action$.pipe(
            ofType(forward),
            tap(() => this.location.forward()),
            map(() => navigationLaunched())
        )
    );
}
