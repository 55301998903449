import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import localeFr from '@angular/common/locales/fr';
import { CoreModule } from './core/core.module';
import { appRouterReducer } from './core/store/reducers/router.reducers';

registerLocaleData(localeFr);

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        /**
         * appRouterReducer allow us to have actual route datas (params, query params, url, data, ...) available in our store (router store)
         * sessionStorageUserSyncReducer synchronize a part of logged user infos to session storage
         */
        StoreModule.forRoot(appRouterReducer, {
            runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true },
        }),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: true }),
        CoreModule,
    ],
    declarations: [AppComponent],
    providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
    bootstrap: [AppComponent],
})
export class AppModule {}
