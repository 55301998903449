/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { go } from '../store/actions/router.actions';
import { AppRouterState } from '../store/reducers/router.reducers';

/**
 * HTTP error interceptor / errors handler
 */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private routerStore: Store<AppRouterState>, private messageService: MessageService) {}

    /**
     * Intercept HTTP request for HTTP error handlings
     * @param request HttpRequest
     * @param next HttpHandler
     * @returns Observable<HttpEvent<any>>
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError((error) => this.handleResponseError(error, request, next)));
    }

    /**
     * Hanle HTTP error by redirecting to appropriate error page
     * depending on error status code, or just showing an error toaster for some cases
     * @param error HttpErrorResponse
     * @param request HttpRequest<any>
     * @param next HttpHandler
     * @returns Observable<HttpEvent<any>>
     */
    // eslint-disable-next-line max-lines-per-function, complexity
    private handleResponseError(
        error: HttpErrorResponse,
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (error instanceof HttpErrorResponse) {
            switch (error.status) {
                case HttpStatusCode.Unauthorized:
                    this.routerStore.dispatch(go({ path: ['/error-pages/unauthorized'] }));
                    break;

                case HttpStatusCode.Forbidden:
                    this.routerStore.dispatch(go({ path: ['/error-pages/access-denied'] }));
                    break;

                case HttpStatusCode.InternalServerError: {
                    const trackingIdentifier = error.error?.trackingIdentifier;
                    this.routerStore.dispatch(go({ path: ['/error-pages/error'], query: { trackingIdentifier } }));
                    break;
                }

                case HttpStatusCode.ServiceUnavailable:
                    this.routerStore.dispatch(go({ path: ['/error-pages/unavailable-service'] }));
                    break;

                case HttpStatusCode.RequestTimeout:
                case HttpStatusCode.GatewayTimeout:
                    this.messageService.add({
                        key: 'app-toaster',
                        severity: 'error',
                        summary: 'Delai depassé',
                        // eslint-disable-next-line quotes
                        detail: `Le temps d'attente de la requête a été écoulé. Le serveur a mis trop de temps à repondre`,
                    });
                    break;

                default:
                    this.routerStore.dispatch(go({ path: ['/error-pages/error'] }));
                    break;
            }
        }
        return throwError(() => error);
    }
}
